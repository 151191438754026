body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow Condensed", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 700 !important;
}

p {
  line-height: 1.6;
  margin: 0 0 20px !important;
}

section {
  margin: 0 auto;

  padding: 20px;
  text-align: left;

  .content {
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
    padding: 1rem 0;
  }
}

$brand-primary: #6877a8;
$brand-secondary: #53649c;
$brand-background: #1d232a;
